import request from "./http.js";

// 获取评论列表
export const getListData = (data) => {
  return request({
    url: "/backend/newscomment/list",
    method: "get",
    params: data,
  });
};

// 添加/修改虚拟评论
export const addTalk = (data) => {
  return request({
    url: "/backend/newscomment/save",
    method: "post",
    data,
  });
};

// 获取评论详情
export const getData = (data) => {
  return request({
    url: "/backend/newscomment/details",
    method: "get",
    params: data,
  });
};

// 评论隐藏、展示
export const changeStatus = (data) => {
  return request({
    url: "/backend/newscomment/change",
    method: "get",
    params: data,
  });
};

// 评论是否置顶
export const changeTop = (data) => {
  return request({
    url: "/backend/newscomment/istop",
    method: "post",
    data,
  });
};

// 评论回复
export const replyTalk = (data) => {
  return request({
    url: "/backend/newscomment/reply_mes",
    method: "post",
    data,
  });
};

// 评论删除
export const delTalk = (data) => {
  return request({
    url: "/backend/newscomment/delete",
    method: "get",
    params: data,
  });
};
