<template>
  <el-card class="box-card">
    <div
        slot="header"
        style="
          display: flex;
          justify-content: space-between;
          padding: 0 50px 0 10px;
        "
        class="clearfix"
    >
      <h3 class="inBlock">评论管理</h3>
      <back></back>
    </div>
    <div class="container" ref="container">
      <div class="top" style="margin-bottom: 20px">
        <el-form ref="filterForm" v-model="filterForm" inline>
          <el-form-item label="发布时间：">
            <el-date-picker
                v-model="Daterange"
                type="datetimerange"
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                align="left"
                value-format="yyyy-MM-dd HH:mm:ss"
                :default-time="['', '23:59:59']"
                clearable
            />
          </el-form-item>
          <el-form-item label="评论类型:">
            <el-select v-model="filterForm.comment_type" placeholder="请选择" clearable>
              <el-option label="虚拟" :value="2" />
              <el-option label="真实" :value="1" />
            </el-select>
          </el-form-item>
          <el-form-item label="是否置顶：">
            <el-select v-model="filterForm.is_top" placeholder="请选择" clearable>
              <el-option label="否" :value="2" />
              <el-option label="是" :value="1" />
            </el-select>
          </el-form-item>
          <el-form-item label="是否隐藏：">
            <el-select v-model="filterForm.is_hide" placeholder="请选择" clearable>
              <el-option label="隐藏" :value="2" />
              <el-option label="显示" :value="1" />
            </el-select>
          </el-form-item>
          <el-form-item label="会员名称：">
            <el-input v-model="filterForm.user_name" clearable placeholder="请输入会员名称"></el-input>
          </el-form-item>
          <el-form-item label="资讯标题：">
            <el-input v-model="filterForm.title" clearable placeholder="请输入资讯标题"></el-input>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" icon="el-icon-search" @click="serchTable">搜索</el-button>
          </el-form-item>
        </el-form>
        <el-button type="danger" @click="openLayer(1)">批量删除</el-button>
        <el-button type="primary" @click="openLayer(2)">批量回复</el-button>
        <el-button type="primary" plain @click="addTalk(1)">新增评价</el-button>
      </div>
      <div class="content">
        <el-table
          :data="tableData"
          border
          :max-height="containerHeight - 250"
          stripe
          ref="table"
        >
          <el-table-column type="selection" width="55" align="center"></el-table-column>
          <template v-for="(item, index) in tableColumn">
            <el-table-column
              v-if="item.prop == 'is_hide'"
              :key="'a'+index"
              :width="item.width"
              :fixed="item.fixed"
              :prop="item.prop"
              :label="item.label"
              align="center"
            >
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.is_hide"
                  @click.native="switchChange(1, scope.row, scope.$index)"
                  :active-value="1"
                  :inactive-value="2"
                  :disabled='true'
                />
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="item.prop == 'is_top'"
              :key="'a'+index"
              :width="item.width"
              :fixed="item.fixed"
              :prop="item.prop"
              :label="item.label"
              align="center"
            >
              <template slot-scope="scope">
                <el-switch
                  v-model="scope.row.is_top"
                  @click.native="switchChange(2, scope.row, scope.$index)"
                  :active-value="1"
                  :inactive-value="2"
                  :disabled='true'
                />
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="item.prop == 'comment_type'"
              :key="'a'+index"
              :width="item.width"
              :fixed="item.fixed"
              :prop="item.prop"
              :label="item.label"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.comment_type === 1 ? '真实' : '虚拟' }}
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="item.prop == 'is_reply'"
              :key="'a'+index"
              :width="item.width"
              :fixed="item.fixed"
              :prop="item.prop"
              :label="item.label"
              align="center"
            >
              <template slot-scope="scope">
                {{ scope.row.reply_content ? '是' : '否' }}
              </template>
            </el-table-column>
            <el-table-column
              v-else-if="item.prop == 'user_avatar'"
              :key="'a'+index"
              :width="item.width"
              :fixed="item.fixed"
              :prop="item.prop"
              :label="item.label"
              align="center"
            >
              <template slot-scope="scope">
                <template v-if="scope.row.user_avatar.indexOf(':') != -1">
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="scope.row.user_avatar"
                    :preview-src-list="[scope.row.user_avatar]"
                    fit="contain"
                  ></el-image>
                </template>
                <template v-else>
                  <el-image
                    style="width: 100px; height: 100px"
                    :src="imgurl + scope.row.user_avatar"
                    :preview-src-list="[imgurl + scope.row.user_avatar]"
                    fit="contain"
                  ></el-image>
                </template>
              </template>
            </el-table-column>
            <el-table-column
              v-else
              :key="'a'+index"
              :prop="item.prop"
              :label="item.label"
              :fixed="item.fixed"
              :width="item.width"
              align="center"
            />
          </template>
          <el-table-column type="option" label="操作" width="200" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click="openLayer(1, scope.row)"><span style="color: #f78989">删除</span></el-button>
              <el-button type="text" @click="openLayer(2, scope.row)">回复</el-button>
              <el-button v-if="scope.row.comment_type == 2" type="text" @click="addTalk(2, scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <el-pagination
          @current-change="SetPage"
          :current-page="filterForm.page"
          :page-size="filterForm.limit"
          layout="total, prev, pager, next, jumper"
          :total="total"
      >
      </el-pagination>
    </div>

    <el-dialog :title="layerTitle" :visible.sync="layer" width="30%" :before-close="closeLayer">
      <div>
        <template v-if="layerType==1">是否确认批量删除评论</template>
        <template v-else-if="layerType==2">是否确认删除该评论</template>
        <template v-else-if="layerType==3 || layerType==4">
          <el-form ref="answerForm" v-model="answerForm">
            <el-form-item>
              <el-input type="textarea" v-model="answerForm.content"/>
            </el-form-item>
          </el-form>
          <div v-if="layerType == 4">批量操作后所有已选评价的回复内容一致</div>
        </template>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="layer = false">取 消</el-button>
        <el-button type="primary" @click="submitThis">确 定</el-button>
      </span>
    </el-dialog>

    <el-drawer
        :title="layerTitle"
        :before-close="closeLayer"
        :visible.sync="talkingDrawer"
        custom-class="demo-drawer"
        ref="drawer"
    >
      <div class="demo-drawer__content">
        <el-form :model="talkingForm" ref="talkingForm">
          <el-form-item
            label="评价人昵称"
            prop="user_name"
            :rules="{ required: true, message: '请输入评价人昵称', trigger: 'change' }"
          >
            <el-input v-model="talkingForm.user_name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item
            label="评价人头像"
            prop="user_avatar"
            :rules="{ required: true, message: '请上传评价人头像', trigger: 'change' }"
          >
            <template v-if="talkingForm.user_avatar">
              <el-input v-model="imgurl+talkingForm.user_avatar" :disabled="true" placeholder="头像"></el-input>
              <div class="image">
                <img :src="imgurl+talkingForm.user_avatar" alt="" />
                <span class="shou" @click="delMinimg">×</span>
              </div>
            </template>
            <template v-else>
              <br>
              <up-img @addimg="upMinimg" :type="'user'"></up-img>
            </template>
          </el-form-item>
          <el-form-item label="评价时间">
            <el-date-picker
              v-model="talkingForm.addtime"
              type="datetime"
              placeholder="选择日期时间"
              align="right"
              value-format="yyyy-MM-dd HH:mm:ss"
            >
            </el-date-picker>
          </el-form-item>
          <el-form-item
            label="评价内容"
            prop="content"
            :rules="{ required: true, message: '请输入评价内容', trigger: 'change' }"
          >
            <el-input type="textarea" v-model="talkingForm.content" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button @click="talkingDrawer = false">取 消</el-button>
            <el-button type="primary" @click="submitTalking('talkingForm')">确 定</el-button>
          </el-form-item>
        </el-form>
      </div>
    </el-drawer>
  </el-card>
</template>
<script>
import {getSystemsettings} from "@/api/getSystemsetting";
import {addTalk, changeStatus, changeTop, delTalk, getListData, replyTalk} from "@/api/talkManage";
import UpImg from "@/components/upImg.vue";
export default {
  name: "manageTalk",
  components: {
    UpImg
  },
  data() {
    return {
      // 容器宽高
      containerHeight: null,
      containerWidth: null,
      // oss前缀
      imgurl: '',
      // 筛选表单
      filterForm: {
        limit: 10,
        page: 1,
        comment_type: '',
        is_top: '',
        is_hide: '',
        user_name: '',
        start: '',
        end: ''
      },
      total: 20,
      // 表格数据
      tableData: [],
      Daterange: [],
      // 表头数据
      tableColumn: [
        {
          label: '编号',
          prop: 'id',
          width: 50,
          fixed: false,
        },
        {
          label: '资讯标题',
          prop: 'title',
          width: 200,
          fixed: false,
        },
        {
          label: '评价内容',
          prop: 'content',
          width: 300,
          fixed: false,
        },
        {
          label: '展示状态',
          prop: 'is_hide',
          width: 80,
          fixed: false,
        },
        {
          label: '是否虚拟评价',
          prop: 'comment_type',
          width: 80,
          fixed: false,
        },
        {
          label: '是否优质评价',
          prop: 'is_top',
          width: 80,
          fixed: false,
        },
        {
          label: '是否已回复',
          prop: 'is_reply',
          width: 80,
          fixed: false,
        },
        {
          label: '评论人昵称',
          prop: 'user_name',
          width: 130,
          fixed: false,
        },
        {
          label: '评论人头像',
          prop: 'user_avatar',
          width: 150,
          fixed: false,
        },
        {
          label: '发布时间',
          prop: 'addtime',
          width: 180,
          fixed: false,
        }
      ],
      // 被选中行
      ids: [],
      // 回复表单
      answerForm: {
        content: ''
      },
      // 弹窗
      layer: false,
      // 弹窗类型 1批量删除，2单个删除，3批量回复，4单个回复
      layerType: null,
      // 弹窗标题
      layerTitle: '',
      // 添加评论抽屉
      talkingDrawer: false,
      // 评论表单
      talkingForm: {},

    }
  },
  watch: {
    Daterange: function (newVal, oldVal) {
      if (newVal != null) {
        this.filterForm.start = newVal[0]
        this.filterForm.end = newVal[1]
      } else {
        this.filterForm.start = "";
        this.filterForm.end = "";
      }
    }
  },
  computed: {
    id() {
      return this.$route.query.id
    }
  },
  methods: {
    /**
     * 分页器
     * @param e
     */
    SetPage(e) {
      this.filterForm.page = e
      this.getList()
    },
    /**
     * 开关
     * @param tp      类型：1展示状态；2是否优质评价
     * @param row     行数据
     * @param index   行下标
     */
    async switchChange(tp, row, index) {
      let data
      switch (tp) {
        case 1:
          data = await changeStatus({id:row.id})
          if (data.data.code==200) {
            if (this.tableData[index].is_hide === 1) {
              this.tableData[index].is_hide = 2
            } else {
              this.tableData[index].is_hide = 1
            }
          }
          break
        case 2:
          data = await changeTop({id:row.id})
          if (data.data.code==200) {
            if (this.tableData[index].is_top === 1) {
              this.tableData[index].is_top = 2
            } else {
              this.tableData[index].is_top = 1
            }
          }
          break
      }
      this.$message({
        message: data.data.data,
        type: data.data.code==200 ? 'success' : 'error'
      });
    },
    /**
     * 删除
     * @param tp    类型1：删除；2：回复
     * @param row   行数据
     */
    openLayer(tp, row) {
      if (this.$refs.table.selection.length>0){
        let ids = []
        this.$refs.table.selection.forEach(item=>{
          ids.push(item.id)
        })
        this.ids = [...ids]
      }
      switch (tp) {
        case 1:
          if (row) {
            this.layerTitle = '删除评论'
            this.layerType = 2
            this.ids.push(row.id)
            break
          }
          if (!this.ids.length>0) {
            this.$message.error('请选择要批量删除的数据')
            return
          }
          this.layerTitle = '批量删除评论'
          this.layerType = 1
          break
        case 2:
          if (row) {
            this.layerTitle = '回复评论'
            this.layerType = 3
            this.ids.push(row.id)
            break
          }
          if (!this.ids.length>0) {
            this.$message.error('请选择要批量回复的数据')
            return;
          }
          this.layerTitle = '批量回复评论'
          this.layerType = 4
          break
      }
      this.layer = true
    },
    /**
     * 弹窗提交内容
     */
    async submitThis() {
      let data,formData
      formData = {
        id : this.ids.toString()
      }
      if (this.layerType === 1 || this.layerType === 2) {
        data = await delTalk(formData)
        this.$message({
          message: data.data.data,
          type: data.data.code==200 ? 'success' : 'error'
        });
        setTimeout(()=>{
          this.getList()
        },2000)
      } else {
        if (!this.answerForm.content) {
          this.$message.error('请填写回复内容')
          return
        }
        formData.content = this.answerForm.content
        data = await replyTalk(formData)
      }
      this.$message({
        message: data.data.data,
        type: data.data.code==200 ? 'success' : 'error'
      });
      this.closeLayer()
    },
    /**
     * 关闭弹窗
     */
    closeLayer() {
      this.layerType = null
      this.ids = []
      this.talkingForm = {}
      this.talkingForm= {
        user_name: '',
        user_avatar: '',
        addtime: '',
        content: ''
      }
      this.layerTitle = ''
      this.answerForm = {}
      this.layer = false
      this.talkingDrawer = false
    },
    /**
     * 新增评价
     * @param tp  类型  1新增，2修改
     * @param row  行数据
     */
    addTalk(tp, row){
      if (tp === 1) {
        this.layerTitle = '新增评论'
        this.talkingForm= {
          user_name: '',
          user_avatar: '',
          addtime: '',
          content: ''
        }
      } else {
        this.layerTitle = '编辑评论'
        this.talkingForm = this.deepClone(row)
        this.talkingForm.id = row.id
      }
      this.talkingForm.news_id = this.id
      this.talkingDrawer = true
    },
    /**
     * 提交虚拟评论
     * @param form  表单
     */
    submitTalking(form) {
      let that = this
      this.$refs[form].validate((valid) => {
        if (valid) {
          addTalk(that.talkingForm).then(res=>{
            that.$message({
              message: res.data.data,
              type: res.data.code==200 ? 'success' : 'error'
            });
            setTimeout(()=>{
              that.closeLayer()
              that.getList()
            },2000)

          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    async getList() {
      this.filterForm.id = this.id
      let {data} = await getListData(this.filterForm)
      this.tableData = data.data.list.data
      this.total = data.data.list.total
    },
    /**
     * 删除图片
     */
    delMinimg() {
      this.talkingForm.user_avatar = "";
    },
    /**
     * 上传图片
     * @param e
     */
    upMinimg(e) {
      this.talkingForm.user_avatar = e;
    },
    /**
     * 对象深拷贝
     * @param obj       传入对象
     * @returns {any}   返回对象
     */
    deepClone(obj) {
      return JSON.parse(JSON.stringify(obj));
    },
    /**
     * 搜索表格数据
     */
    serchTable() {
      this.filterForm.page = 1
      this.getList()
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {
    getSystemsettings.then((res) => {
      this.imgurl = res;
    });
    this.getList()
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.containerHeight = this.$refs['container'].offsetHeight
    this.containerWidth = this.$refs['container'].offsetWidth
  },
  //生命周期 - 创建之前
  beforeCreate() {
  },
  //生命周期 - 挂载之前
  beforeMount() {
  },
  //生命周期 - 更新之前
  beforeUpdate() {
  },
  //生命周期 - 更新之后
  updated() {
  },
  //生命周期 - 销毁之前
  beforeDestroy() {
  },
  //生命周期 - 销毁完成
  destroyed() {
  },
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {
  },
}
</script>
<style scoped lang="less">
.container {
  padding: 20px;
  height: 100%;
}
/deep/.demo-drawer {
  .el-drawer__body{
    padding: 20px;
  }
  .el-drawer__header{
    margin-bottom: 0;
  }
  .el-upload {
    display: flex;
    width: 130px;
    height: 130px;
    .avatar-uploader-icon{
      width: 100%;
      height: 100%;
      line-height: 130px;
    }
  }
  .image {
    font-size: 12px;
    width: 130px;
    height: 130px;
    color: #aaa;
    border: 1px solid #eeeeee;
    position: relative;
    top: -10px;
    margin: 10px 10px 0 0;

    img {
      width: 130px;
      height: 130px;
    }

    span {
      position: absolute;
      top: 0;
      right: 0;
      width: 20px;
      text-align: center;
      line-height: 20px;
      font-size: 12px;
      height: 20px;
      background-color: #ffd1d1;
      color: #fff3f3;
      z-index: 9;

      &:hover {
        background-color: #ff4544;
        color: #ffffff;
      }
    }
  }
}
</style>
